/**
 * Stores build information about <b>this specific version of the `ruffle-core` library</b>.
 *
 * It does not represent the version of Ruffle that may be in use by the page (for example, if a browser extension overrides it).
 *
 * @privateRemarks
 * This is generated at build time via `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2025-01-04",
    versionChannel: "nightly",
    buildDate: "2025-01-04T00:06:15.414Z",
    commitHash: "75dba08fa9c776bc57238ee25e66e0584e89c6ee",
};
